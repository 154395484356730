import input from '@/assets/languages/german/input';
import button from '@/assets/languages/german/button';
import app from '@/assets/languages/german/app';
import categories from '@/assets/languages/german/categories';

const german = {
    title: 'MarketPrint',
    button: button,
    input: input,
    app: app,
    categories: categories,
};

export default german;
