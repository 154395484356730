import input from '@/assets/languages/english/input';
import button from '@/assets/languages/english/button';
import app from '@/assets/languages/english/app';
import categories from '@/assets/languages/english/categories';

const english = {
    title: 'MarketPrint',
    button: button,
    input: input,
    app: app,
    categories: categories,
};

export default english;
