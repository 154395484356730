export enum UnitSystem {
    METRIC = 'metric',
    IMPERIAL = 'imperial',
}

export enum ShoeSizeSystem {
    EU = 'eu',
    UK = 'uk',
    US = 'us',
}

export enum Language {
    DE = 'de',
    EN = 'en',
    FR = 'fr',
}

export const LanguageNames: { [key: string]: { label: string; flag: string } } = {
    [Language.DE]: { label: 'Deutsch', flag: '/images/flags/de.svg' }, // "German"
    [Language.EN]: { label: 'English', flag: '/images/flags/gb.svg' }, // "English"
    [Language.FR]: { label: 'Français', flag: '/images/flags/fr.svg' }, // "French"
};

export const LanguageUnitMapping: { [key: string]: { unit: UnitSystem; shoeSize: ShoeSizeSystem } } = {
    [Language.DE]: { unit: UnitSystem.METRIC, shoeSize: ShoeSizeSystem.EU },
    [Language.EN]: { unit: UnitSystem.IMPERIAL, shoeSize: ShoeSizeSystem.EU },
    [Language.FR]: { unit: UnitSystem.METRIC, shoeSize: ShoeSizeSystem.EU },
};
