
import Vue from "vue";
import i18n from "@/plugins/vue-i18n";

export default Vue.extend({
    name: "App",
    mounted() {
      addChatBot();
    },
    watch: {
      '$i18n.locale': function(){
        addChatBot();
      }
    }
});

let chatBotScriptDe:  HTMLScriptElement | null = null;
let chatBotScriptEn:  HTMLScriptElement | null = null;

const addChatBot = () => {
    if(chatBotScriptDe && i18n.locale === 'de' || chatBotScriptEn && i18n.locale === 'en') return;

    removeAllChatBots();

    if(i18n.locale === 'de') initChatBotDe();
    else initChatBotEn();
    !function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}()
};

const removeAllChatBots = () => {
    if("function" === typeof window.FreshworksWidget) {
      window.FreshworksWidget('destroy');
    }
    if(chatBotScriptEn) {
      chatBotScriptEn.remove();
      chatBotScriptEn = null;
    }
    if(chatBotScriptDe){
      chatBotScriptDe.remove();
      chatBotScriptDe = null;
    }

    do {
      document.getElementById('freshworks-container')?.remove();
    } while(document.getElementById('freshworks-container'));
    do {
      document.getElementById('freshworks-frame')?.remove();
    } while(document.getElementById('freshworks-frame'));
}

const initChatBotDe = () => {
    window.fwSettings = { widget_id: 101000008205 };
    window.FreshworksWidget = null;

    chatBotScriptDe = document.createElement("script");
    chatBotScriptDe.setAttribute(
      "src",
      "https://euc-widget.freshworks.com/widgets/101000008205.js",
    );
    document.head.appendChild(chatBotScriptDe);
};

const initChatBotEn = () => {
    window.fwSettings = { widget_id: 101000008472 };
    window.FreshworksWidget = null;

    chatBotScriptEn = document.createElement("script");
    chatBotScriptEn.setAttribute(
      "src",
      "https://euc-widget.freshworks.com/widgets/101000008472.js",
    );
    document.head.appendChild(chatBotScriptEn);
};
