import { API_URL, useApi } from "@/composables/useApi";
import { RequestOption } from "@/types/frontend/functionTypes";
import { AxiosResponse } from "axios";

const { api } = useApi();

const request = async <T = unknown>(
    reqParams: RequestOption,
): Promise<T | undefined> => {
    const { method, route, body, query, responseType, isRootURL } = reqParams;

    const response = await api
        .request({
            method: method,
            url: route,
            data: body,
            params: query,
            responseType: responseType,
            ...(isRootURL && {
                baseURL: API_URL.replace("/shopifyApp", ""),
            }),
        })
        .catch((err) => {
            console.log(err);
            return undefined;
        });

    if (!response) return undefined;

    return response.data;
};

const requestWithPagination = async <T = unknown>(
    reqParams: RequestOption,
): Promise<T | undefined> => {
    const { method, route, body, query, responseType } = reqParams;

    const response = await api
        .request({
            method: method,
            url: route,
            data: body,
            params: query,
            responseType: responseType,
        })
        .catch((err) => {
            console.log(err);
            return undefined;
        });

    if (!response) return undefined;

    return response.data;
};

const requestFile = async (
    reqParams: RequestOption,
    uploadCallback?: Function,
) => {
    const { route, body, responseType } = reqParams;
    let responseError = null;

    const response = await api
        .postForm(route, body, {
            responseType,
            onUploadProgress: (progressEvent) => {
                if (uploadCallback) uploadCallback(progressEvent);
            },
        })
        .catch((err) => {
            responseError = err;
        });

    if (responseError) {
        throw responseError;
    }

    if (!response) return undefined;

    return response;
};

const requestAll = async (reqParams: RequestOption): Promise<AxiosResponse> => {
    const { method, route, body, query, responseType } = reqParams;

    return await api
        .request({
            method: method,
            url: route,
            data: body,
            params: query,
            responseType: responseType,
        })
        .catch((err) => {
            throw err.response;
        });
};

export { request, requestWithPagination, requestFile, requestAll };
