import input from '@/assets/languages/french/input';
import button from '@/assets/languages/french/button';
import app from '@/assets/languages/french/app';
import categories from '@/assets/languages/french/categories';

const french = {
    title: 'MarketPrint',
    button: button,
    input: input,
    app: app,
    categories: categories,
};

export default french;
