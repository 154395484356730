import axios from 'axios';
import { API_URL, SHOP_KEY } from '@/composables/useApi';
import { AuthCredentials, AuthResult } from '@/types/backend/functionTypes';
import router from '@/router';

let authKey = '';
const baseUrl = ENV.OAUTH_URL;
export function useAuth() {
    const authRequest = async (path: string, credentials?: AuthCredentials): Promise<AuthResult | undefined> => {
        //* Here Axios so that Authorization Header is not used!!
        const response = await axios.post(API_URL + path, credentials).catch(error => {
            console.log(error);
            return undefined;
        });

        if (!response) return undefined;

        if (response.data?.token) {
            setToken(response.data.token);
            return { valid: true, isAdmin: response.data.isAdmin };
        }
        return { valid: false };
    };

    function setToken(key: string) {
        const shopKey = localStorage.getItem(SHOP_KEY);

        if (!shopKey) return;

        localStorage.setItem(shopKey, key);

        authKey = key;
    }

    function getToken() {
        if (authKey !== '') {
            return authKey;
        } else {
            const shop = localStorage.getItem(SHOP_KEY);

            if (!shop) return null;

            const key = localStorage.getItem(shop);
            if (key) {
                authKey = key;
                return authKey;
            }

            return null;
        }
    }

    const setShopKey = (shop: string) => {
        const oldCustomerNewsState = localStorage.getItem('CUSTOMER_NEWSES_STATE');
        localStorage.clear();
        localStorage.setItem(SHOP_KEY, shop);
        if (oldCustomerNewsState) localStorage.setItem('CUSTOMER_NEWSES_STATE', oldCustomerNewsState);
    };
    function removeToken() {
        const shop = localStorage.getItem(SHOP_KEY);
        if (shop) localStorage.removeItem(shop);

        localStorage.removeItem(SHOP_KEY);
    }

    const checkHmac = async () => {
        const queryParams = new URLSearchParams(window.location.search);
        const shop = queryParams.get('shop');
        const hmac = queryParams.get('hmac');
        const host = queryParams.get('host');
        const session = queryParams.get('session');
        const timestamp = queryParams.get('timestamp');

        if (!shop || typeof shop !== 'string') return;

        const queryParamsString = `?hmac=${hmac}&host=${host}&session=${session}&shop=${shop}&timestamp=${timestamp}`;
        const response = await axios.get(baseUrl + queryParamsString);
        if (response.data.url) {
            setShopKey(String(shop));
            window.location = response.data.url;
            await new Promise(resolve => setTimeout(resolve, 5000)); //prevent app from showing if we are redirecting
        }

        // const authShop = localStorage.getItem(SHOP_KEY);
        // const token = localStorage.getItem(query.shop);
        // if (authShop === query.shop && token && token.length > 0) return;
    };
    return {
        checkHmac,
        setShopKey,
        authRequest,
        getToken,
        setToken,
        removeToken,
    };
}
