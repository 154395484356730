import { BvModal } from "bootstrap-vue";
import retryInit from "@/composables/useInit";
import thisVue from "@/main";
import { MsgBoxConfirmOptions } from "@/types/frontend/bootstrapFunctionTypes";

export let modalController: BvModal;

let init = false;

export function useModal() {
    const initModalController = () => {
        if (init) return;

        modalController = thisVue.$bvModal;

        if (!modalController) throw Error("Modalcontroller not init");

        init = true;
    };

    retryInit(initModalController, 10);
}

const createMsgBoxConfirm = (creationParams: MsgBoxConfirmOptions) => {
    const {
        header,
        contentText,
        okTitle,
        cancelTitle,
        okHandler,
        cancelHandler,
    } = creationParams;
    modalController
        ?.msgBoxConfirm(contentText, {
            title: header,
            okVariant: "danger",
            okTitle: okTitle,
            cancelTitle: cancelTitle,
            modalClass: "base-modal",
            centered: true,
        })
        .then((value) => {
            if (value) return okHandler();

            if (cancelHandler) cancelHandler();
        });
};

const createOKBox = (creationParams: {
    header: string;
    contentText: string;
    okTitle: string;
}) => {
    const { header, contentText, okTitle } = creationParams;
    modalController?.msgBoxOk(contentText, {
        title: header,
        okTitle: okTitle,
        modalClass: "base-modal",
        centered: true,
    });
};

export { createMsgBoxConfirm, createOKBox };
