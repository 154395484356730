import Axios, { AxiosInstance } from 'axios';
import { useAuth } from '@/api-connectors/auth';

export const API_URL = ENV.API_URL;
export const CDN_URL = ENV.CDN_URL;
export const SHOP_KEY = 'authKey';

const { getToken, removeToken } = useAuth();

export function useApi() {
    const api: AxiosInstance = Axios.create();
    let initDone = false;

    const init = async () => {
        if (initDone) return;
        api.defaults.baseURL = API_URL;

        api.interceptors.request.use(async function (config) {
            if (!config.headers) config.headers = {};
            config.headers['Authorization'] = 'Bearer ' + getToken();
            return config;
        });

        api.interceptors.request.use(async function (config) {
            return config;
        });

        api.interceptors.response.use(
            function (response) {
                // Any status code that lie within the range of 2xx cause this function to trigger
                return response;
            },
            function (error: { response: { status: number } }) {
                return Promise.reject(error);
            }
        );

        api.interceptors.response.use(
            function (response) {
                return response;
            },
            function (error) {
                if (error.response.status === 401) {
                    removeToken();
                    window.location.href = '/login';
                }

                return Promise.reject(error);
            }
        );

        initDone = true;
    };

    init();

    return {
        api,
    };
}
